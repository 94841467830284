.login-page {
  text-align: center;
  position: relative;
  padding:50px;
  background-image: url('../assets/my-background.webp');
}

.login-container {
  background-color: rgba(255, 251, 251, 0.9);
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  max-width: 550px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  text-align: right; /* Align text inside the label to the right */
  direction: rtl;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  direction: rtl;
}

button {
  padding: 10px 20px;
  background-color: #4aa1c9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button:hover {
  background-color: #ffffff;
  color: #4aa1c9;
}

.back-button {
  background-color: #555353;
  color: white;
}

.back-button:hover {
  background-color: #333;
}

.toggle-button {
  background-color: transparent;
  color: #4aa1c9;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.toggle-button:hover {
  color: #555353;
}

.message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(240, 240, 240, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.message button {
  background: none;
  border: none;
  color: inherit;
  font-weight: bold;
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

.overlay.active {
  display: block;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}
