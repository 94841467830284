.admin-page {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Open Sans Hebrew', sans-serif;
    direction: rtl; /* Right-to-left text direction */
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
  }
  
  .search-bar-users {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .user-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .user-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .action-btn, .delete-btn {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-btn {
    background-color: #21004a;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .action-btn:hover {
    background-color: #0056b3;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
  }
  