.content {
  top: 100%;
}

.nav-bar {
  background-color: #21004a;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10%;
  width: 100%;
}

.nav-bar button {
  padding: 10px 20px;
  background-color: #21004a;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nav-bar button:hover {
  background-color: #4aa1c9;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu button {
  padding: 10px 20px;
  background-color: #444;
  color: white;
  border: none;
  text-align: right;
  white-space: nowrap;
}

.dropdown-menu button:hover {
  background-color: #555;
}

.background {
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  width: 80%;
  height: 85vh;
  position: relative;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  z-index: 0;
  right: -10%;
  margin: 15px;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.whoAreWe {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 5px;
  margin-right: 120px;
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.whoAreWe-content {
  max-width: 600px;
  line-height: 1.5;
  text-align: right;
}

.whoAreWe h1 {
  margin-top: 0;
}

.whoAreWe p {
  margin: 0;
  padding-bottom: 0px;
}

.whoAreWe-image {
  max-width: 600px;
  margin-left: 30px;
  padding-left: 50px;
  
}

.whoarewe-para {
  font-size: 20px;
  font-weight: 545;
  font-family: 'Open Sans Hebrew';
  font-style: normal;
}

.button-label {
  margin-top: 10px;
  font-size: 20px;
  color: #333;
}

.introduction {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 80%;
}

.intro-image2 {
  background-size: cover;
  height: 300px;
  padding: 0% 0%;
}

.intro-text {
  max-width: 100%;
  line-height: 1.5;
  text-align: center;
  color: #210055;
  text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1em rgb(255, 255, 255), 0 0 0.2em rgb(255, 255, 255);
  font-size: 50px;
  padding-right: 120px;
  margin: 0%;
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.intro-par {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  font-size: 25px;
  text-align: right;
  color: #323232;
  text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1em rgb(255, 255, 255), 0 0 0.2em rgb(255, 255, 255);
  padding-right: 120px;
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.button-label {
  background-color: rgb(72, 156, 172);
  border-radius: 5px;
  display: block;
  width: 300px;
  color: white;
}

/* Gallery */
.pictures {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 80vh;
}

.box {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
}

.box > img {
  width: 200%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.box:hover {
  flex: 1 1 50%;
  height: calc(100% - 10vh);
}

.box:hover > img {
  width: 100%;
  height: 100%;
}

.admin-button-container {
  position: absolute;
  top: 220px;
  left: 10px;

}

.admin-button {
  background-color: #21004a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.admin-button:hover {
  background-color: #4aa1c9;
}