.nav-bar-container {
  border-radius: 0px;
  margin-bottom: 0px;
}

.nav-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 13px 19px;
}

.nav-bar button {
  padding: 10px 20px;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
  font-weight: bold; /* Make font bold */
  font-family: 'Open Sans Hebrew';
  font-style: normal;
}

.nav-bar button:hover {
  background-color: #b0e9ff;
  color: rgb(72, 156, 172);
}

.nav-bar button.active {
  background-color: #b0e9ff;
  color: rgb(72, 156, 172);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0; /* Align to the right */
}

.dropdown-content button {
  width: 100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: right; /* Align text to the right */
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #ddd;
  color: black;
}

.icon-label {
  position: absolute;
  padding: 10px 15px;
  color: gold;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Align the label in the center vertically */
  left: 6%;
}

.icon-label:hover {
  color: white;
}

.text-label {
  position: absolute;
  color: white;
  border-radius: 5px;
  font-size: 14px; /* Smaller font size for the label */
  align-self: center; /* Align the label in the center vertically */
  left: 50%;
  font-size: large;
  font-weight: bold; /* Make font bold */
}

.home-icon {
  position: absolute;
  padding: 15px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  right: 0.5%;
  font-size: 1.2rem; /* Adjust size as needed */
}

.home-icon:hover {
  color: rgb(72, 156, 172);
}

.auth-button {
  position: absolute;
  right: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
}

.auth-button button {
  padding: 5px 10px;
  background-color: rgb(72, 156, 172);
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: small;
}

.auth-button button:hover {
  background-color: #b0e9ff;
  color: rgb(72, 156, 172);
}

.search-bar {
  position: absolute;
  left: 20%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.search-form input {
  position: absolute;
  left: 1%;
  top: 23%;
  padding: 5px;
  border: none;
}

.search-form button {
  position: absolute;
  left: 13%;
  cursor: pointer;
  height: 5%;
  width: 2%;
  
}

.search-form button:hover {
  background-color: #21004a;
  color: rgb(72, 156, 172);
}
