.content-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
}

.content-item {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  direction: rtl; /* Ensure all content is displayed from right to left */
  text-align: right; /* Align text to the right */
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
  font-size: larger;
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
  font-size: larger;
}

.author-name {
  font-weight: bold;
}

.post-date {
  color: #999;
}

.section h3, .section p {
  margin: 10px 0;
  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
  font-size: larger;
}

.media-container {
  margin: 20px 0;
  text-align: center;
  font-size: larger;
}

.uploaded-image {
  width: 800px;
  height: 400px;
  border-radius: 10px;
}

.delete-button {
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-button:hover {
  background-color: #c0392b;
}

.edit-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}

.edit-button:hover {
  background-color: #2a88b9;
}

.read-more-label {
  color: #4aa1c9;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}

.read-more-label:hover {
  text-decoration: underline;
}

.post-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.like-button, .dislike-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
  color: #4aa1c9;
}

.like-button.active, .dislike-button.active {
  color: #126992;
}

.like-button.disabled, .dislike-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
