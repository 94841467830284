.rating-page {
  text-align: center;
  background: linear-gradient(to top, #555353, #fcfcfc);
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  padding: 50px;
  background-image: url('../assets/my-background.webp');
  background-size: cover;
}

.rating-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 40%;
  text-align: center;
}

.stars {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.star {
  font-size: 2rem;
  cursor: pointer;
  color: #ddd;
  margin: 0 5px;
}

.star.selected {
  color: #f39c12;
}

.rating-form textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  direction: rtl; /* Ensure the text inside the textarea is right-to-left */
  margin-bottom: 15px;
}

button {
  padding: 10px 20px;
  background-color: #4aa1c9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #ffffff;
  color: #4aa1c9;
}

.feedback-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 50%;
  text-align: left;
  overflow-y: auto; /* Allow scrolling for feedback container */
  height: auto; /* Adjust height to fit content */
}

.feedback-item {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-rating {
  display: flex;
}

.feedback-user {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #666;
}

.star-percentages {
  margin-top: 20px;
}

.star-percentage {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.star-percentage .star {
  margin-right: 10px;
}

.percentage-bar {
  background-color: #ddd;
  height: 20px;
  flex: 1;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.filled-bar {
  background-color: #f39c12;
  height: 100%;
}

.percentage-text {
  margin-left: 10px;
}

.star-count {
  margin-left: 5px;
  font-size: 1rem;
  color: #666;
  padding-right: 7px;
}

.sorting-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sorting-buttons button {
  padding: 10px 20px;
  background-color: #21004a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sorting-buttons button:hover {
  background-color: #3a007a;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.delete-button:hover {
  background-color: darkred;
}

.edit-button {
  background-color: #21004a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.edit-button:hover {
  background-color: #3a007a;
}
