/* index.css */
body {
  /* background: linear-gradient(to top, #ffffff, #fcfcfc);
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to top, #ffffff, #ffffff);
  background: linear-gradient(to top, #ffffff, #ffffff);  */
  background-image: url('assets/watercolor.avif');
	background-size: cover;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  
}

.App {
  text-align: center;
}

h1 {
  color: #333;
}
