.search-results-page {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;

  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.search-result-item {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: right;

  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.search-result-item img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.read-more-label,
.navigate-label {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  margin-top: 10px;

  font-family: 'Open Sans Hebrew';
  font-weight: normal;
  font-style: normal;
}

.read-more-label:hover,
.navigate-label:hover {
  color: #0056b3;
}


.post-path {
  position: absolute;
  right: 23.5%;
  font-size: 0.9em;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.post-path:hover {
  color: #0056b3;
}