.footer {
  background-color: #210055;
  color: white;
  padding: 20px 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  font-size:larger;

  font-family: 'Open Sans Hebrew';
  font-style: inherit;
}

.footer-section {
  flex: 1;
  padding: 10px;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section p,
.footer-section ul {
  margin: 0;
  padding: 0;
}

.footer-section ul {
  list-style: none;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #00aeff;
}

.footer-bottom {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #555;
  font-size: large;
}

.line {
  width: 90px; /* Adjust the width as needed */
  height: 1.8px;
  background-color: #9b0000;
  margin: 10px auto;
}

.yo_icon{
  color: crimson;
  padding: 10px;
}

.fa_icon{
  color: rgb(74, 132, 218);
}
