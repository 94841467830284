/* src/Pages/LinguisticEducationPage.css */

.linguistic-education-page {
    text-align: center;
    padding: 20px;
  }
  
  .content {
    margin-top: 20px;
  }
  