.edit-post-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto; /* Add this to make the content scrollable */
}

.close-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  float: right;
}

.media-container img, .media-container video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

.remove-section-button, .remove-file-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.remove-section-button:hover, .remove-file-button:hover {
  background-color: #c0392b;
}

.edit-form {
  max-height: 80vh;
  overflow-y: auto;
}
