.header-container {
    text-align: center;
    
    width: 100%;
    height: 100%;
    margin-bottom: -20px;
  }
  
  .header-container .logo {
    width: 100%;
    height: 100%;
    margin-bottom: -13px;
  }
  
  /* .nav-bar-container {
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .nav-bar {
    background-color: #333;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: inherit;
  }
  
  .nav-bar button {
    padding: 10px 20px;
    background-color: rgba(164, 162, 155, 0.8);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

   */