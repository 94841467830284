.content-upload {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.content-upload h2 {
  margin-bottom: 20px;
}

.content-upload form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-upload input[type="text"],
.content-upload input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.section {
  margin-bottom: 20px;
  width: 100%;
}

.section .ql-container {
  margin-bottom: 10px;
}

.content-upload button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.content-upload button:hover {
  background-color: #ffffff;
  color: #4aa1c9;
}

.remove-section-button {
  padding: 5px 10px;
  background-color: #e74c3c;
  color: #e74c3c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
}

.remove-section-button:hover {
  background-color: #c0392b;
}

